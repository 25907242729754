export const siteName = "Snowy Sanal Market";
export const siteNameUpper = "SNOWY SANAL MARKET";
export const googleStoreUrl =
  "https://play.google.com/store/apps/details?id=com.marketyo.snowy&hl=tr";
export const appleStoreUrl =
  "https://apps.apple.com/tr/app/snowy-market/id1380633801";
export const siteMailList = [
  {
    mail: "onlinesiparis@snowymarket.com",
    subject: "Bize Mail Bırakın",
  },
];
export const sitePhoneList = [{ phone: "0 (530) 917 90 22", info: "" }];
export const eklenenMiktarKatsayisi = 0.1;

/* ============================================================================================== */
/*                            appstora yonlendirme mesaji ile ilgili                              */
/* ============================================================================================== */
export const askPlatformMessage = true; // yonlendirme sorulacak mi
export const askPlatformCounter = 5; // her kac giriste sorulacak (0 ve 1 icin surekli sorar)
export const useAskNeverButton = false; // bir daha sorma butonu aktif mi


/* ============================================================================================== */
/*                                  kategori side bar ile ilgili                                  */
/* ============================================================================================== */
export const categorySideBarType = 1 //1 yeni versiyon A101 ornegi, degilse orjinal versiyon
